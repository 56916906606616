import { useMutation } from 'react-query'

import { SupportPandasRequestBody } from '@/api/generated'
import { useApi } from '@/providers'

class AuthLoginRequestBody {}

export const useSupport = () => {
  const api = useApi()
  const fetchFn = async (req: SupportPandasRequestBody) => {
    try {
      return (
        await api.base.support({
          supportPandasRequestBody: req,
        })
      ).data.actual_donation
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const mutation = useMutation(fetchFn)

  return {
    support: mutation.mutateAsync,
    ...mutation,
  }
}
