import styled from 'styled-components'

import { breakpoints } from '@/utils'

export const HeaderWrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 54rem;
  padding-left: 70rem;
  z-index: 10;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    padding-top: 14rem;
    padding-left: 20rem;
  }
`

export const Logo = styled.div`
  width: 324rem;
  height: 75rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    width: 206rem;
    height: 46rem;
  }
`
