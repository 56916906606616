import React from 'react'

import * as S from './conditions-styled'

type ConditionsProps = {
  onClose: () => void
}

export const Conditions: React.FC<ConditionsProps> = ({onClose}) => {
    return (
        <>
          <S.Title>Условия проведения акции</S.Title>
          <S.Inner>
           <S.ButtonClose onClick={onClose} />
            <S.Text>
            Условия проведения акции
            </S.Text>
          </S.Inner>
        </>
    )
}
