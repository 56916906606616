import React from 'react'

import logos from '@/assets/img/vector/logos.svg'

import * as S from './header-styled'

export const Header: React.FC = () => {
  return (
    <S.HeaderWrapper>
      <a href="https://pari.ru" target="_blank" rel="noreferrer">
        <S.Logo>
          <img src={logos} alt="Pari x Nine Pandas" />
        </S.Logo>
      </a>
    </S.HeaderWrapper>
  )
}
