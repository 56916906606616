import { useEffect, useState } from 'react'

export const useModal = () => {
  const [modalState, setModalState] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const toggle = () => {
    setModalState(!modalState)
    setTimeout(() => {
      setIsActive(!isActive)
    }, 200)
  }

  useEffect(() => {
    const closeModalPressEsc = (e: { key: string }) => {
      if (e.key === 'Escape') {
        toggle()
      }
    }
    if (modalState) {
      document.body.classList.add('no-scroll')
      document.addEventListener('keyup', closeModalPressEsc)
      return () => {
        document.removeEventListener('keyup', closeModalPressEsc)
        document.body.classList.remove('no-scroll')
      }
    }
  }, [modalState, toggle])

  return {
    modalState,
    isActive,
    toggle,
  }
}
