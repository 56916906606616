import React, { useState } from 'react'

import { Button, Conditions, Modal, Support } from '@/components'
import { useModal, usePariLogin } from '@/hooks'
import { useGetDonation, useSupport } from '@/hooks/base'
import { checkAuthCookies, getAuthCookies } from '@/utils/auth-cookies'

import * as S from './main-content.styled'

const LS_SUCCESS_KEY = 'pari-success-donation'

export const MainContent: React.FC = () => {
  const [isSuccess, setSuccess] = useState(!!localStorage.getItem(LS_SUCCESS_KEY))
  const modalRiles = useModal()
  const modalSuccess = useModal()
  const { actualDonation, refetch: refetchActualDonation } = useGetDonation()
  const { support } = useSupport()
  const pariLogin = usePariLogin()

  const fetchSupport = async () => {
    const { customerid, fsid } = getAuthCookies()

    support({
      cid: customerid,
      fsid,
    }).then(() => {
      localStorage.setItem(LS_SUCCESS_KEY, '1')
      setSuccess(true)
      refetchActualDonation()
      modalSuccess.toggle()
    })
  }

  const AuthClickHandler = () => {
    if (!checkAuthCookies()) {
      pariLogin().then(fetchSupport)
      return
    }
    fetchSupport()
  }

  return (
    <S.MainContentWrapper>
      <S.Title>
        Поддержим <span>панд</span>
      </S.Title>
      <S.SupportWrapper>
        <S.Sum>{new Intl.NumberFormat('ru-RU').format(actualDonation ?? 0)}</S.Sum>
        <S.Support>Уже переведено! С&nbsp;тебя регистрация - с&nbsp;нас 100 рублей в&nbsp;поддержку панды</S.Support>
      </S.SupportWrapper>
      <Button type="big" onClick={AuthClickHandler} disabled={isSuccess}>
        {isSuccess ? 'Спасибо' : 'Накормить панду'}
      </Button>
      <S.Conditions onClick={modalRiles.toggle}>Условия</S.Conditions>
      <S.Information>
        <S.InformationText>
          30&nbsp;августа в&nbsp;Москве <span className="bold">родилась панда.</span> В&nbsp;честь этого события
          букмекерская компания <span className="color">PARI</span> вместе с&nbsp;участниками{' '}
          <span className="italic">The&nbsp;International 2023</span> киберспортивной командой{' '}
          <span className="bold">9Pandas</span>&nbsp; уже <span className="bold">выделили 1&nbsp;миллион рублей</span>
          &nbsp; на&nbsp;ее&nbsp;содержание, а&nbsp;с&nbsp;твоей помощью мы&nbsp;
          <span className="bold">хотим увеличить эту сумму</span>
        </S.InformationText>
      </S.Information>
      <Modal opened={modalRiles.modalState} onClose={modalRiles.toggle} active={modalRiles.isActive}>
        <Conditions onClose={modalRiles.toggle} />
      </Modal>

      <Modal opened={modalSuccess.modalState} onClose={modalSuccess.toggle} active={modalSuccess.isActive}>
        <Support onClose={modalSuccess.toggle} />
      </Modal>
    </S.MainContentWrapper>
  )
}
