import React, { PropsWithChildren } from 'react'

import ReactDOM from 'react-dom'

import { useMount } from '@/hooks'

import * as S from './modal.styled'

const modalRoot = document.getElementById('react-modals') as HTMLElement

interface ModalsProps extends PropsWithChildren {
  opened: boolean
  active: boolean | null
  onClose: () => void
}

export const Modal: React.FC<ModalsProps> = ({ opened, onClose, children, active }) => {
  const { mounted } = useMount({ opened })

  if (!mounted) {
    return null
  }

  return ReactDOM.createPortal(
    <S.Modal>
      <S.Overlay onClick={onClose} />
      <S.Container className={active ? 'active' : 'notactive'}>
        {children}
      </S.Container>
    </S.Modal>,
    modalRoot
  )
}
