import styled from 'styled-components'

import leftSticker from '@/assets/img/left-sticker.png'
import rightSticker from '@/assets/img/right-sticker.png'
import arrow from '@/assets/img/vector/arrow.svg'
import textDecor from '@/assets/img/vector/text-decor.svg'
import titleDecor from '@/assets/img/vector/title-decor.svg'
import { breakpoints, colors } from '@/utils'

export const MainContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: -50rem;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    margin-top: 0;
    padding-top: 75rem;
    justify-content: flex-start;
  }
`

export const Title = styled.h1`
  position: relative;
  display: inline-block;
  margin-bottom: 43px;
  font-family: 'druk-cyr';
  font-weight: 700;
  font-size: 134rem;
  color: ${colors.WHITE};
  text-transform: uppercase;
  text-align: center;

  span {
    font-weight: 1000;
    font-style: italic;
    color: ${colors.LAZUR};
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 3rem;
    right: -31rem;
    width: 308rem;
    height: 25rem;
    background-image: url(${titleDecor});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    max-width: 260rem;
    margin-left: -20rem;
    margin-bottom: 64px;
    font-size: 72rem;
    line-height: 102.1%;
    text-align: left;

    span {
      font-size: 72rem;
      line-height: 102.1%;
    }

    &::before {
      bottom: -12rem;
      right: 108rem;
      width: 157rem;
      height: 14rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 76rem;
      left: 20rem;
      width: 22.713rem;
      height: 16.495rem;
      transform: rotate(5.388deg);
      background-image: url(${arrow});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`

export const Sum = styled.p`
  display: inline-block;
  margin-bottom: 18rem;
  font-size: 94.613rem;
  font-style: italic;
  font-weight: 500;
  line-height: 100%;
  color: ${colors.CODGRAY};
  font-family: 'druk-wide', Arial, Helvetica, sans-serif;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    margin-bottom: 5rem;
    font-size: 31rem;
  }
`

export const Support = styled.p`
  position: relative;
  display: inline-block;
  width: 594rem;
  font-size: 29rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: ${colors.CODGRAY};
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    bottom: -26rem;
    right: 15rem;
    width: 305rem;
    height: 12rem;
    transform: rotate(-2deg);
    background-image: url(${textDecor});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    width: 226rem;
    font-size: 11rem;

    &::before {
      bottom: -13rem;
      right: 5rem;
      width: 109.815rem;
      height: 12.975rem;
      transform: rotate(0.078deg);
    }
  }
`

export const SupportWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 43rem;
  margin-bottom: 53rem;
  width: 926.823rem;
  height: 277.168rem;
  transform: rotate(1.425deg);
  border: 2.28rem solid ${colors.CODGRAY};
  background-color: ${colors.WHITE};

  &::before {
    content: '';
    position: absolute;
    bottom: -51rem;
    right: -61rem;
    width: 161rem;
    height: 162rem;
    background-image: url(${rightSticker});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    top: -59rem;
    left: -81rem;
    width: 161rem;
    height: 162rem;
    background-image: url(${leftSticker});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    margin: 0 auto;
    margin-bottom: 60rem;
    padding-top: 10rem;
    width: 278.618rem;
    height: 83.321rem;
    transform: rotate(1.425deg);
    border: 0.685rem solid ${colors.CODGRAY};

    &::before {
      bottom: -38rem;
      right: -16rem;
      width: 49.083rem;
      height: 49.388rem;
      transform: rotate(-5.007deg);
    }

    &::after {
      top: -33rem;
      left: -21rem;
      width: 49.004rem;
      height: 49.004rem;
      transform: rotate(-2.368deg);
    }
  }
`

export const Conditions = styled.a`
  display: inline-block;
  margin-top: 4rem;
  font-size: 20rem;
  font-weight: 400;
  line-height: 116%;
  text-decoration-line: underline;
  color: ${colors.WHITE};
  margin-bottom: 36rem;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    margin-top: 11rem;
    margin-bottom: 28rem;
    color: ${colors.BLACK};
    font-size: 13rem;
  }
`

export const Information = styled.div`
  display: flex;
  width: 828rem;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    padding-top: 20rem;
    padding-bottom: 20rem;
    padding-left: 15rem;
    padding-right: 15rem;
    width: 280rem;
    min-height: 211rem;
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.BLACK};
    transform: rotate(2.738deg);
  }
`

export const InformationText = styled.p`
  font-size: 21rem;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  color: ${colors.WHITE};
  text-align: center;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    font-size: 15rem;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
    color: ${colors.BLACK};

    .bold {
      font-weight: 600;
    }

    .italic {
      font-style: italic;
      font-weight: 500;
    }

    .color {
      font-weight: 600;
      text-transform: uppercase;
      color: ${colors.GREEN};
    }
  }
`
