import styled from 'styled-components'

import { breakpoints,colors } from '@/utils'

export const Title = styled.h2`
  display: inline-block;
  padding: 40rem;
  font-family: 'druk-wide-cyr', Arial, Helvetica, sans-serif;
  font-size: 28rem;
  font-style: italic;
  font-weight: 700;
  line-height: 116%;
  color: ${colors.BLACK};
  text-transform: uppercase;

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
   padding: 0;
   padding-bottom: 21rem;
   padding-right: 40rem;
    font-size: 10rem;
  }
`

export const Inner = styled.div`
  width: 100%;
  padding: 40rem;
  min-height: 390rem;
  overflow-y: scroll;
  border-top: 1rem solid ${colors.GRAY};

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    padding: 0;
    padding-top: 21rem;
    min-height: 305rem;
  }
`

export const Text = styled.p`
  font-size: 19rem;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  color: ${colors.BLACK};

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    font-size: 13rem;
  }
`

export const ButtonClose = styled.div`
  position: absolute;
  top: 28rem;
  right: 40rem;
  width: 24rem;
  height: 24rem;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 27rem;
    width: 1.5rem;
    background-color: ${colors.BLACK};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: ${breakpoints.DESKTOP}px) {
    width: 15rem;
    height: 15rem;
    right: 21rem;
    top: 20rem;

    &::before,
    &::after {
      height: 15rem;
    }
  }
`
