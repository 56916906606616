import Cookies from 'js-cookie'

export enum CookiesPari {
  CID = 'headerApi.cid',
  FSID = 'headerApi.FSID',
}

export function getAuthCookies() {
  const authData = {
    customerid: 0,
    fsid: '',
  }

  const queryCid = new URLSearchParams(location.search).get('cid')
  const queryFsid = new URLSearchParams(location.search).get('fsid')

  if (queryCid && queryFsid) {
    authData.customerid = +queryCid
    authData.fsid = queryFsid
    return authData
  }

  const cookieCid = Cookies.get(CookiesPari.CID)
  const cookieFsid = Cookies.get(CookiesPari.FSID)

  if (cookieCid && cookieFsid) {
    authData.customerid = +cookieCid
    authData.fsid = cookieFsid
    return authData
  }

  return authData
}

export function clearCookies() {
  deleteCookie(CookiesPari.CID)
  deleteCookie(CookiesPari.FSID)
  Cookies.remove(CookiesPari.CID)
  Cookies.remove(CookiesPari.FSID)
}

export function checkAuthCookies() {
  return (
    [CookiesPari.CID, CookiesPari.FSID].every((name) => Cookies.get(name)) ||
    (new URLSearchParams(location.search).get('cid') && new URLSearchParams(location.search).get('fsid'))
  )
}

// Для удаление кук установленных сервером pari
function deleteCookie(name: string) {
  const options = {
    path: '/',
    domain: '.pari.ru',
  }

  let updatedCookie = encodeURIComponent(name) + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC'

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey

    const optionValue = options[optionKey as keyof typeof options]

    updatedCookie += '=' + optionValue
  }

  document.cookie = updatedCookie
}
