import styled, { css } from 'styled-components'

import { breakpoints, colors } from '@/utils'

interface ButtonProps {
  disabled?: boolean
  type?: 'big' | 'small'
}

export const Button = styled.a<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border-radius: 122rem;
  border: 1.582rem solid ${colors.CODGRAY};
  background-color: ${colors.LAZUR};
  font-family: 'druk-cyr', Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 53rem;
  line-height: 1.2;
  color: ${colors.CODGRAY};
  letter-spacing: 1.59rem;
  text-transform: uppercase;
  outline: none;
  transition: opacity 0.2s;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${({ type }) =>
    type === 'big' &&
    css`
      width: 445.875rem;
      height: 87rem;
    `}

  ${({ type }) =>
    type === 'small' &&
    css`
      font-size: 37.8rem;
      width: 227rem;
      height: 63rem;
    `}

  @media screen and (min-width: ${breakpoints.DESKTOP}px) {
    &:hover {
      opacity: 0.85;
    }
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    font-size: 21.481rem;

    ${({ type }) =>
      type === 'big' &&
      css`
        width: 228.625rem;
        height: 44.61rem;
        border-radius: 62.551rem;
        border: 0.811rem solid ${colors.CODGRAY};
        font-size: 27rem;
      `}

    ${({ type }) =>
      type === 'small' &&
      css`
        width: 129rem;
        height: 35.802rem;
        border-radius: 49.765rem;
        border: 0.645rem solid ${colors.CODGRAY};
      `}
  }
`
