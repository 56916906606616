import styled, { css } from 'styled-components'

import { breakpoints, colors } from '@/utils'

interface ModalProps {
  type?: 'conditions' | 'support'
}

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20rem;
  min-height: 100vh;
  z-index: 5;
  overflow: auto;

  @media (max-width: ${breakpoints.DESKTOP}px) {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 88rem;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 20rem;
  padding-bottom: 20rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.92);

  @media (max-width: ${breakpoints.DESKTOP}px) {
    background-color: black;
  }
`

export const Container = styled.div<ModalProps>`
  position: relative;
  margin: auto;
  width: fit-content;
  height: fit-content;
  min-width: 961rem;
  background-color: ${colors.WHITE};
  border: 1rem solid ${colors.BLACK};
  z-index: 4;
  transform: scale(0);
  opacity: 0;

  ${({ type }) =>
    type === 'support' &&
    css`
      width: 445.875rem;
      height: 87rem;
    `}

  &.active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }

  &.notactive {
    transform: scale(0);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: ${breakpoints.DESKTOP}px) {
    padding: 20rem;
    width: 280rem;
    min-width: 280rem;
    border: none;
    border-radius: unset;
    box-shadow: none;
    opacity: 1;
    transform: scale(1);

    &.notactive {
      opacity: 1;
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
  }
`

export const Title = styled.h2`
  margin-bottom: 10rem;
  font-weight: 900;
  font-size: 35rem;
  line-height: 43rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.DESKTOP}px) {
    font-weight: 800;
    font-size: 24rem;
    line-height: 120%;
  }
`

export const ButtonClose = styled.div`
  position: absolute;
  top: 28rem;
  right: 40rem;
  width: 24rem;
  height: 24rem;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 27rem;
    width: 1.5rem;
    background-color: ${colors.BLACK};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: ${breakpoints.DESKTOP}px) {
    width: 15rem;
    height: 15rem;

    &::before,
    &::after {
      height: 15rem;
    }
  }
`
