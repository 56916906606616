import { checkAuthCookies } from '@/utils/auth-cookies'

export const usePariLogin = () => {
  return async () => {
    const width = 1210
    const height = 650
    const top = window.innerHeight / 2 - height / 2
    const left = window.innerWidth / 2 - width / 2
    const authWindow = window.open(
      `https://www.pari.ru/account${window.location.search}`,
      'popUpWindow',
      `height=${height},width=${width},left=${left},top=${top},resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=no`
    )

    return new Promise<void>((resolve, reject) => {
      const interval = setInterval(() => {
        if (authWindow?.closed) {
          stop()
          reject(new Error('Unauthorized'))
        } else if (checkAuthCookies()) {
          authWindow?.close()
          stop()
          resolve()
        }
      }, 500)

      const stop = () => {
        clearInterval(interval)
      }
    })
  }
}
