type Breakpoints = 'MOBILE' | 'DESKTOP'
type Colors = 'LAZUR' | 'WHITE' | 'BLACK' | 'CODGRAY' | 'GRAY' | 'GREEN' | 'SILVER'
export const breakpoints: Record<Breakpoints, number> = {
  MOBILE: 320,
  DESKTOP: 999.9,
}

export const colors: Record<Colors, string> = {
  LAZUR: '#00C7B1',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  CODGRAY: '#0C0C0C',
  GRAY: '#AAAAAA',
  GREEN: '#00A08F',
  SILVER: '#A9A9A9',
}
