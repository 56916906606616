import styled, { css } from 'styled-components'

import topCard from '@/assets/img/10Panda.png'
import rightSticker from '@/assets/img/left-sticker.png'
import leftSticker from '@/assets/img/right-sticker.png'
import bottomCard from '@/assets/img/support.png'
import heartOne from '@/assets/img/vector/heart-1.svg'
import heartTwo from '@/assets/img/vector/heart-2.svg'
import heartThree from '@/assets/img/vector/heart-3.svg'
import { breakpoints, colors } from '@/utils'

type TextProps = {
  type?: 'small'
}

type StickerProps = {
  type: 'right' | 'left'
}

type CardsProps = {
  type: 'top' | 'bottom'
}

export const Logo = styled.div`
  margin-bottom: 54rem;
  width: 158rem;
  height: 66rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    margin-bottom: 36rem;
    width: 73.089rem;
    height: 27.711rem;
  }
`

export const Title = styled.h2`
  display: inline-block;
  padding-bottom: 50rem;
  font-family: 'druk-wide-cyr', Arial, Helvetica, sans-serif;
  font-size: 33rem;
  font-style: italic;
  font-weight: 900;
  line-height: 116%;
  color: ${colors.BLACK};

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    padding-bottom: 34rem;
    font-size: 17rem;
  }
`

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 26rem;
  padding-bottom: 40rem;
  width: 100%;
  height: 100%;
  background-color: ${colors.WHITE};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 53rem;
    left: 90rem;
    width: 206rem;
    height: 207.185rem;
    background-image: url(${heartOne});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -10rem;
    right: 40rem;
    width: 525rem;
    height: 487.61rem;
    background-image: url(${heartTwo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;
  }

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    padding-top: 0;
    padding-bottom: 0;

    &::before {
      content: none;
    }

    &::after {
      right: -20rem;
      width: 278rem;
      height: 352rem;
      background-image: url(${heartThree});
    }
  }
`

export const Text = styled.p<TextProps>`
  position: relative;
  display: inline-block;
  padding-bottom: 40rem;
  max-width: 738rem;
  font-size: 19rem;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  color: ${colors.BLACK};
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 640rem;
    height: 1rem;
    background-color: ${colors.SILVER};
  }

  ${({ type }) =>
    type === 'small' &&
    css`
      padding-top: 30rem;
      padding-bottom: 60rem;

      &::before {
        content: none;
      }

      .color {
        color: ${colors.GREEN};
        font-weight: 600;
        cursor: pointer;
      }
    `}

  @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    padding-bottom: 24rem;
    max-width: 246rem;
    font-size: 13rem;

    &::before {
        width: 218rem;
    }

    ${({ type }) =>
      type === 'small' &&
      css`
        padding-top: 20rem;
        padding-bottom: 27rem;
        font-size: 11rem;
      `}
  }
`

export const Sticker = styled.div<StickerProps>`
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  ${({ type }) =>
    type === 'left' &&
    css`
      top: 42rem;
      left: -30rem;

      width: 109rem;
      height: 109.677rem;

      background-image: url(${leftSticker});

      @media screen and (max-width: ${breakpoints.DESKTOP}px) {
        top: -7rem;
        left: -35rem;
        width: 50rem;
        height: 50.311rem;
      }
    `}

  ${({ type }) =>
    type === 'right' &&
    css`
      bottom: 7rem;
      right: -70rem;

      width: 207.554rem;
      height: 207.554rem;
      transform: rotate(2.405deg);

      background-image: url(${rightSticker});

      @media screen and (max-width: ${breakpoints.DESKTOP}px) {
        bottom: -55rem;
        right: -31rem;
        width: 76.171rem;
        height: 66.171rem;
      }
    `}
`

export const Cards = styled.div<CardsProps>`
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  ${({ type }) =>
    type === 'top' &&
    css`
      top: -100rem;
      right: -125rem;
      width: 285.618rem;
      height: 285.572rem;

      background-image: url(${topCard});

      @media screen and (max-width: ${breakpoints.DESKTOP}px) {
        top: -73rem;
        right: -57rem;
        width: 140.843rem;
        height: 151.128rem;
      }
    `}

  ${({ type }) =>
    type === 'bottom' &&
    css`
      bottom: -27rem;
      left: -111rem;
      width: 290.308rem;
      height: 304.288rem;

      background-image: url(${bottomCard});

      @media screen and (max-width: ${breakpoints.DESKTOP}px) {
        bottom: -28rem;
        left: -46rem;
        width: 97.767rem;
        height: 109.762rem;
      }
    `}
`
