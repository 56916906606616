import React from 'react'

import { Cards } from './cards.styled'

type CardsProps = {
  image: string
}

export const Card: React.FC<CardsProps> = ({ image }) => {
  return <Cards src={image} />
}
