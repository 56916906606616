import React, { useEffect } from 'react'

import debounce from 'lodash/debounce'

import bottomCards from '@/assets/img/bottom.png'
import leftCards from '@/assets/img/left.png'
import rightCards from '@/assets/img/right.png'
import topCards from '@/assets/img/top.png'
import { ApiProvider, QueryProvider } from '@/providers'
import { GlobalStyles } from '@/styles'

import { Card, CardWrapper, Header, MainContent } from './components'
import { useIsMobile } from './hooks'

export const App = () => {
  const isMobile = useIsMobile()

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 300)

    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <QueryProvider>
      <ApiProvider>
        <GlobalStyles />
        <Header />
        <main>
          {!isMobile ? (
            <CardWrapper type="left">
              <Card image={leftCards} />
            </CardWrapper>
          ) : (
            <CardWrapper type="top">
              <Card image={topCards} />
            </CardWrapper>
          )}

          <MainContent />

          {!isMobile ? (
            <CardWrapper type="right">
              <Card image={rightCards} />
            </CardWrapper>
          ) : (
            <CardWrapper type="bottom">
              <Card image={bottomCards} />
            </CardWrapper>
          )}
        </main>
      </ApiProvider>
    </QueryProvider>
  )
}
