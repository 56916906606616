import { useEffect, useState } from 'react'

import debounce from 'lodash/debounce'

import { breakpoints } from '@/utils'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth <= breakpoints.DESKTOP)

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      setIsMobile(document.body.clientWidth <= breakpoints.DESKTOP)
    }, 200)

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return isMobile
}
