import styled, { css } from 'styled-components'

import { breakpoints } from '@/utils'

interface CardProps {
  type?: 'right' | 'left' | 'top' | 'bottom'
}

export const CardWrapper = styled.div<CardProps>`
  ${({ type }) =>
    type === 'right' &&
    css`
      display: flex;
      margin-left: auto;
      margin-top: 62rem;
      width: 447rem;
      height: 809rem;
    `}

  ${({ type }) =>
    type === 'left' &&
    css`
      display: flex;
      margin-right: auto;
      margin-top: 140rem;
      width: 448rem;
      height: 732rem;
      margin-right: 50rem;
    `}

    @media screen and (max-width: ${breakpoints.DESKTOP}px) {
    ${({ type }) =>
      type === 'top' &&
      css`
        position: absolute;
        top: 147rem;
        right: 16rem;
        display: flex;
        width: 143rem;
        height: 110rem;
      `}

    ${({ type }) =>
      type === 'bottom' &&
      css`
        display: flex;
        margin-top: -6rem;
        width: 312.002rem;
        height: 245.341rem;
      `}
  }
`
