import { BaseApi, Configuration } from '@/api/generated'

import { IApiContext } from './api-context'

interface ICreateApisParams {
  basePath?: string
}

export function createApis({ basePath }: ICreateApisParams): IApiContext {
  const param = {
    basePath,
  }

  return {
    base: new BaseApi(new Configuration(param)),
  }
}
