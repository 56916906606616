import { createGlobalStyle } from 'styled-components'

import desktopBg from '@/assets/img/bg/bg-desktop.jpg'
import mobileBg from '@/assets/img/bg/bg-mobile.jpg'
import { breakpoints, colors } from '@/utils'

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    font-family: 'montserrat', sans-serif;
    font-size: 0.052vw;
    color: ${colors.WHITE};
    font-weight: 500;

    @media screen and (max-width: ${breakpoints.DESKTOP}px) {
      font-size: 0.31125vw;
    }
  }

  body {
    position: relative;
    background-color: ${colors.BLACK};
    background-size: cover;
    background-repeat: repeat;
    background-position-x: center;

    overflow-x: hidden;
    overflow-y: auto;
    min-height: 800rem;
    
    @media screen and (max-width: ${breakpoints.DESKTOP}px) {
      min-height: 1029rem;
      font-size: 0.31125vw;
    }
  }

  body {
    max-width: 100%;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  #root {
    height: 100%;
  }

  main {
    position: absolute;

    display: flex;

    width: 100%;
    height: 100%;

    overflow: hidden;

    background-image: url(${desktopBg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    @media screen and (max-width: ${breakpoints.DESKTOP}px) {
      flex-direction: column;
      justify-content: flex-start;
      background-position: top;
      background-image: url(${mobileBg});
    }
  }
`
