import React from 'react'

import logo from '@/assets/img/vector/pari.svg'
import { Button } from '@/components'

import * as S from './support-styled'

type SupportProps = {
  onClose: () => void
}

export const Support: React.FC<SupportProps> = ({ onClose }) => {
  return (
    <>
      <S.Inner>
        <S.Sticker type='left'/>
        <S.Sticker type='right'/>

        <S.Cards type='top'/>
        <S.Cards type='bottom'/>
        
        <S.Logo>
          <img src={logo} alt="PARI" />
        </S.Logo>
        <S.Title>
            Спасибо за твою поддержку!
        </S.Title>
        <S.Text>
        Все средства будут перечислены в&nbsp;программу опеки новорожденной панды в&nbsp;Москве. Они пойдут на&nbsp;её&nbsp;питание и&nbsp;содержание!
        </S.Text>
        <S.Text type='small'>
        С отчетом можно будет ознакомиться на <a href="https://pari.ru" target="_blank" rel="noreferrer" className='color'>странице акции</a>
        </S.Text>
        <Button type="small" onClick={onClose}>
          Хорошо
        </Button>
      </S.Inner>
    </>
  )
}
