import { useQuery } from 'react-query'

import { useApi } from '@/providers'

export const useGetDonation = () => {
  const api = useApi()

  const fetchFn = async () => {
    try {
      return (await api.base.getActualDonation()).data.actual_donation
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const mutation = useQuery('actualDonation', fetchFn, {
    retry: false,
  })

  return {
    actualDonation: mutation.data,
    ...mutation,
  }
}
